const SingleBanner = () => {
  return (
    <div className="single-banner-container">
      <a href="https://www.decathlon.com.br/cartao-presente-50000/p">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/cartao-presente-natal.png"
          alt="cartão presente | Decathlon"
          id="desk"
        />
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/cartao-presente-natal-mob.png"
          alt="cartão presente | Decathlon"
          id="mob"
        />
      </a>
    </div>
  )
}

export default SingleBanner
